import React, {useState} from 'react';
import {Dimmer, Header, Icon, Image, Segment} from 'semantic-ui-react';
import {BASE_STYLES} from '../../Consts';

import _ from 'lodash';
import {motion} from 'framer-motion';
import ChatLine from './Chat/lines/ChatLine';

export default function MainCard({
                                   image,
                                   headers,
                                   headerColor,
                                   subheaders,
                                   subheaderColor,
                                   descriptions,
                                 }) {
  const [isDimmed, setIsDimmed] = useState(false);

  return (
    <Dimmer.Dimmable
      basic
      as={Segment}
      dimmed={isDimmed}
      style={{
        padding: '32px 12px',
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 6px 30px -4px rgba(90, 90, 90, 0.6)',
      }}>
      <Header as="h2" textAlign={'center'}>
        <Header.Content>
          <span style={{
            fontSize: 'clamp(1.2rem, 40vmin, 2.4rem)',
            fontFamily: ['Pretendard'],
            fontWeight: 900,
            color: headerColor || BASE_STYLES.THEME_ITEM_COLOR,
            lineHeight: 1.2,
            wordWrap: 'break-word',
            wordBreak: 'keep-all',
          }}>
            {
              _.map(headers, (header, idx) => (
                <React.Fragment key={`H${idx}`}>
                  {header}
                  {(idx < headers.length - 1) && <br />}
                </React.Fragment>
              ))
            }
          </span>
          <div style={{padding: '6px 0'}} />
          <Header.Subheader>
            <span style={{
              fontSize: 'clamp(1rem, 30vmin, 1.4rem)',
              fontFamily: ['Pretendard'],
              fontWeight: 900,
              color: subheaderColor || '#333333',
            }}>
              {
                _.map(subheaders, (subheader, idx) => (
                  <React.Fragment key={`SH${idx}`}>
                    {subheader}
                    {(idx < subheaders.length - 1) && <br />}
                  </React.Fragment>
                ))
              }
            </span>
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Image src={image} height={200} />
      <Dimmer
        style={{overflow: 'hidden overlay', justifyContent: 'flex-start', zIndex: 800}}
        active={isDimmed}
        onClickOutside={() => setIsDimmed(false)}>
        <div style={{textAlign: 'initial'}}>
          {_.map(descriptions, (description, idx) => (
            <ChatLine key={`DESC${idx}`} line={description} lineIdx={idx}
                      fontStyles={{fontSize: 15, lineHeight: 1.2}} />
          ))}
        </div>
      </Dimmer>

      <motion.div
        style={{position: 'absolute', bottom: 12, right: 12, color: subheaderColor || '#333333', zIndex: 801}}
        whileHover={{scale: 1.6}}
        whileTap={{scale: 0.8}}>
        <Icon
          size={'big'}
          name={isDimmed ? 'remove circle' : 'question circle'} link
          onClick={() => setIsDimmed(prevState => !prevState)} />
      </motion.div>
    </Dimmer.Dimmable>
  );
}
