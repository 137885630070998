import React, {useEffect, useState} from 'react';
import {BrowserRouter, Link, Navigate, Route, Routes} from 'react-router-dom';
import CarmonMainLayout from './layout/Main';
import About from './layout/pages/About';
import HowTo from './layout/pages/HowTo';

import 'semantic-ui-css/semantic.min.css';
import './NewApp.css';
import './config/axios_config';

import NewMain from './layout/NewMain';
import Reservation from './layout/pages/Reservation';
import NotFound from './layout/pages/NotFound';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/*<Route exact path="/" element={<CarmonMainLayout />} />*/}
        <Route index path="/" element={<NewMain />} />
        <Route path="reservation/*">
          <Route index element={<Navigate replace to={'/reservation/step1'} />} />
          {/*<Route path={'step1'} element={<Reservation />} />*/}
          {/*<Route path={'step2'} element={<Reservation />} />*/}
          {/*<Route path={'step3'} element={<Reservation />} />*/}
          {/*<Route path={'step4'} element={<Reservation />} />*/}
          {/*<Route path={'step5'} element={<Reservation />} />*/}

          <Route path={'step1'} element={<Reservation />} />
          <Route path={'step2'} element={<Reservation />} />
          <Route path={'step3'} element={<Reservation />} />
        </Route>
        {/*<Route exact path="/about" element={<About />} />*/}
        {/*<Route exact path="/how-to" element={<HowTo />} />*/}
        <Route path={'*'} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;