import {Grid} from 'semantic-ui-react';
import React from 'react';
import PalBubble from '../bubbles/PalBubble';
import MyBubble from '../bubbles/MyBubble';
import _ from 'lodash';

export default function ChatLine({line, lineIdx, fontStyles}) {
  return (
    <Grid.Row columns={1} style={{padding: 0}}>
      <Grid.Column>
        <div style={{paddingRight: '30%'}}>
          {_.map(line[0], (bubble, idx) => <PalBubble key={`PBB_${idx}`} chat={bubble} lineIdx={lineIdx}
                                                      bbIdx={idx} fontStyles={fontStyles} />)}
        </div>
        <div style={{paddingLeft: '30%'}}>
          {_.map(line[1], (bubble, idx) => <MyBubble key={`MBB_${idx}`} chat={bubble} lineIdx={lineIdx} bbIdx={idx}
                                                     fontStyles={fontStyles} />)}
        </div>
      </Grid.Column>
    </Grid.Row>
  );
}