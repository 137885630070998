import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Accordion,
  Button,
  Card,
  Container,
  Dimmer,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Item,
  List,
  Menu,
  Message,
  Modal,
  Popup,
  Segment,
  Sticky,
  Transition,
} from "semantic-ui-react";
import { BASE_STYLES, CARMON_INFO, NEWS_DATA } from "../Consts";
import MediaQuery, { useMediaQuery } from "react-responsive";
import React, { useEffect, useRef, useState } from "react";
import { isBrowser, isMobile } from "react-device-detect";

import AnimateHeight from "react-animate-height";
import CarmonPrivacyPolicy from "./popup/PrivacyPolicy";
import { Link } from "react-router-dom";
import MOBILE_CONT_101 from "../images/mobile/content101.png";
import MOBILE_CONT_102 from "../images/mobile/content102.png";
import MOBILE_CONT_103 from "../images/mobile/content103.png";
import MOBILE_CONT_201 from "../images/mobile/content201.png";
import MOBILE_CONT_202 from "../images/mobile/content202.png";
import MOBILE_CONT_203 from "../images/mobile/content203.png";
import MOBILE_CONT_301 from "../images/mobile/content301.png";
import MOBILE_CONT_302 from "../images/mobile/content302.png";
import MOBILE_CONT_303 from "../images/mobile/content303.png";
import Navbar from "./component/Navbar";
import PC_CONT_1 from "../images/pc/content1.png";
import PC_CONT_2 from "../images/pc/content2.png";
import PC_CONT_3 from "../images/pc/content3.png";
import ReservationFrom from "./component/ReservationForm";
import Slider from "react-slick";
import Visibility from "@semantic-ui-react/component-visibility";

const MIN_WIDTH_BASE = 760;
const MAX_WIDTH_BASE = MIN_WIDTH_BASE + 1;

const FULL_SIZE_WIDTH = 1600;
const NORMAL_SIZE_WIDTH = 1200;
const SMALL_SIZE_WIDTH = 960;
const MINIMUM_SIZE_WIDTH = 720;
const MIN_HEIGHT = 700;

const CarmonPusher = () => {
  window.onresize = () => {
    calcClientMaxWidth();
  };

  const contextRef = useRef();
  const headerRef = useRef();
  const slider = useRef();

  const [clientMaxWidth, setClientMaxWidth] = useState(FULL_SIZE_WIDTH);

  const [pageVisible, setPageVisible] = useState({});
  const [visibilityState, setVisibilityState] = useState({
    direction: "none",
    height: 0,
    width: 0,
    topPassed: false,
    bottomPassed: false,
    pixelsPassed: 0,
    percentagePassed: 0,
    topVisible: false,
    bottomVisible: false,
    fits: false,
    passing: false,
    onScreen: false,
    offScreen: false,
  });

  const [scrollToY, setScrollToY] = useState(-999);

  const FAQ_ANSWER_STYLE = {
    fontSize: useMediaQuery({ maxWidth: MAX_WIDTH_BASE }) ? "1em" : "1.2em",
    paddingLeft: useMediaQuery({ maxWidth: MAX_WIDTH_BASE }) ? 25 : 40,
  };

  const calcClientMaxWidth = () => {
    const clientWidth = document.documentElement.offsetWidth;

    let pageWidth = MINIMUM_SIZE_WIDTH;

    if (clientWidth >= FULL_SIZE_WIDTH) {
      pageWidth = FULL_SIZE_WIDTH;
    } else if (clientWidth >= NORMAL_SIZE_WIDTH) {
      pageWidth = NORMAL_SIZE_WIDTH;
    } else if (clientWidth >= SMALL_SIZE_WIDTH) {
      pageWidth = SMALL_SIZE_WIDTH;
    }

    setClientMaxWidth(pageWidth);
  };

  const SlideCustomComponent = {
    NextArrow: (props) => {
      const { className, style, onClick } = props;
      return (
        <div className={className} style={{ ...style }} onClick={onClick} />
      );
    },
    PrevArrow: (props) => {
      const { className, style, onClick } = props;
      return (
        <div className={className} style={{ ...style }} onClick={onClick} />
      );
    },
  };

  const SLIDER_SETTING = {
    dots: true,
    arrow: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SlideCustomComponent.NextArrow />,
    prevArrow: <SlideCustomComponent.PrevArrow />,
  };

  useEffect(() => {
    calcClientMaxWidth();
  }, []);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY - 10 <= scrollToY) {
        document.querySelector("input#licensePlateNumber").focus();
        setScrollToY(-999);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollToY]);

  const [activeIndex, setActiveIndex] = useState(null);

  const eventHandler = {
    handleUpdate: (e, { calculations }) => {
      setPageVisible((prevState) => {
        return {
          ...prevState,
          page2: calculations.percentagePassed > 0.1,
          page3: calculations.percentagePassed > 0.3,
          page4: calculations.percentagePassed > 0.5,
        };
      });
      setVisibilityState({ ...calculations });
    },
    handleClick: (e, titleProps) => {
      const { index } = titleProps;

      const newIndex = activeIndex === index ? -1 : index;

      setActiveIndex(newIndex);
    },
  };

  return (
    <React.Fragment>
      <div ref={contextRef}>
        <div>
          <Visibility onUpdate={eventHandler.handleUpdate}>
            <Button
              circular
              content="지금 예약"
              icon="calendar check outline"
              size={isMobile ? "large" : "huge"}
              color={"yellow"}
              label={{
                basic: true,
                pointing: "left",
                content: <Icon name={"arrow up"} size={"large"} fitted />,
                color: "yellow",
              }}
              style={{
                position: "fixed",
                zIndex: 999,
                bottom: isMobile ? "2vh" : "5vh",
                right: isMobile ? "2vh" : "5vh",
                boxShadow: "0px 0px 5px #00000055",
              }}
              onClick={() => {
                const top =
                  window.scrollY +
                  document
                    .querySelector("#carmon-reservation-form")
                    .getBoundingClientRect().y -
                  20;
                setScrollToY(top);
                window.scrollTo({ top: top, left: 0, behavior: "smooth" });
              }}
            />

            {/* Page-1 */}
            <div style={{ minHeight: "100vh" }}>
              <Sticky
                context={contextRef}
                styleElement={{
                  backgroundColor: "#00aed9",
                  border: 0,
                  borderStyle: "dotted",
                  borderBottomColor: "#f0f0f0",
                  borderBottomWidth: window.scrollY >= 80 ? 1 : 0,
                }}
              >
                <Navbar />
              </Sticky>

              <Container>
                <Grid columns="equal" stackable>
                  <Grid.Row verticalAlign="middle">
                    <Grid.Column>
                      <Segment basic>
                        <div className="main-header">
                          내차팔기
                          <br />
                          새로운
                          <br />
                          <span style={{ color: "#FEB948" }}>경험</span>
                        </div>
                      </Segment>
                      <Segment basic style={{ marginTop: "6vh" }}>
                        <div
                          className="main-header-description"
                          style={{ color: "#004a81" }}
                        >
                          <span style={{ color: "#FFFFFF" }}>불편</span>은{" "}
                          <span
                            className="sub-header-point"
                            style={{ color: "#FEB948" }}
                          >
                            싹
                          </span>{" "}
                          빼고
                          <br />
                          <span style={{ color: "#FFFFFF" }}>가격</span>만{" "}
                          <span
                            className="sub-header-point"
                            style={{ color: "#FEB948" }}
                          >
                            확
                          </span>{" "}
                          올리다
                        </div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <div
                        id="carmon-reservation-form"
                        className="ui container reservation-form-wrapper"
                      >
                        <ReservationFrom />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </div>

            <section
              style={{
                background: "#FBB649",
                height: useMediaQuery({ minWidth: MIN_WIDTH_BASE })
                  ? "calc(100vh - 78px)"
                  : "100%",
                minHeight: useMediaQuery({ minWidth: MIN_WIDTH_BASE })
                  ? MIN_HEIGHT
                  : "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MediaQuery minWidth={MIN_WIDTH_BASE}>
                <Grid style={{ maxWidth: clientMaxWidth }}>
                  <Grid.Row columns={1}>
                    <Grid.Column
                      textAlign="center"
                      style={{ textAlign: "center", paddingBottom: "50px" }}
                    >
                      <div
                        className="section-header"
                        style={{ fontSize: "6rem" }}
                      >
                        카몬, <span style={{ color: "#ae0000" }}>불편</span>을
                        빼다.
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={3}>
                    <Grid.Column verticalAlign="top">
                      <CarmonProcessImage image={MOBILE_CONT_101} index={11} />
                    </Grid.Column>

                    <Grid.Column verticalAlign="top">
                      <CarmonProcessImage image={MOBILE_CONT_102} index={12} />
                    </Grid.Column>

                    <Grid.Column verticalAlign="top">
                      <CarmonProcessImage image={MOBILE_CONT_103} index={13} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {/*<Image src={PC_CONT_1} style={{display: 'block', margin: '0 auto'}} />*/}
              </MediaQuery>
              <MediaQuery maxWidth={MAX_WIDTH_BASE}>
                <section
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 30,
                    padding: "40px 20px",
                  }}
                >
                  <Grid>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <div
                          className="section-header"
                          style={{ color: "#ffffff" }}
                        >
                          카몬, <span style={{ color: "#ae0000" }}>불편</span>을
                          빼다.
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <CarmonProcessImage
                          image={MOBILE_CONT_101}
                          index={11}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <CarmonProcessImage
                          image={MOBILE_CONT_102}
                          index={12}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <CarmonProcessImage
                          image={MOBILE_CONT_103}
                          index={13}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </section>
              </MediaQuery>
            </section>

            <section
              style={{
                background: "#f0f0f0",
                height: useMediaQuery({ minWidth: MIN_WIDTH_BASE })
                  ? "calc(100vh - 78px)"
                  : "100%",
                minHeight: useMediaQuery({ minWidth: MIN_WIDTH_BASE })
                  ? MIN_HEIGHT
                  : "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MediaQuery minWidth={MIN_WIDTH_BASE}>
                <Grid style={{ maxWidth: clientMaxWidth }}>
                  <Grid.Row columns={1}>
                    <Grid.Column
                      textAlign="center"
                      style={{ textAlign: "center", paddingBottom: "50px" }}
                    >
                      <div
                        className="section-header"
                        style={{ fontSize: "6rem", color: "#696969" }}
                      >
                        카몬, <span style={{ color: "#FEB948" }}>가격</span>만
                        올리다.
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={3}>
                    <Grid.Column verticalAlign="top">
                      <CarmonProcessImage image={MOBILE_CONT_201} index={21} />
                    </Grid.Column>

                    <Grid.Column verticalAlign="top">
                      <CarmonProcessImage image={MOBILE_CONT_202} index={22} />
                    </Grid.Column>

                    <Grid.Column verticalAlign="top">
                      <CarmonProcessImage image={MOBILE_CONT_203} index={23} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </MediaQuery>

              <MediaQuery maxWidth={MAX_WIDTH_BASE}>
                <section
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 30,
                    padding: "40px 20px",
                  }}
                >
                  <Grid>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <div
                          className="section-header"
                          style={{ color: "#696969" }}
                        >
                          카몬, <span style={{ color: "#FEB948" }}>가격</span>만
                          올리다.
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <CarmonProcessImage
                          image={MOBILE_CONT_201}
                          index={21}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <CarmonProcessImage
                          image={MOBILE_CONT_202}
                          index={22}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <CarmonProcessImage
                          image={MOBILE_CONT_203}
                          index={23}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </section>
              </MediaQuery>
            </section>

            <section
              style={{
                background: "#00aed9",
                height: useMediaQuery({ minWidth: MIN_WIDTH_BASE })
                  ? "calc(100vh - 78px)"
                  : "100%",
                minHeight: useMediaQuery({ minWidth: MIN_WIDTH_BASE })
                  ? MIN_HEIGHT
                  : "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MediaQuery minWidth={MIN_WIDTH_BASE}>
                <Grid style={{ maxWidth: clientMaxWidth }}>
                  <Grid.Row columns={1}>
                    <Grid.Column
                      textAlign="center"
                      style={{ textAlign: "center", paddingBottom: "50px" }}
                    >
                      <div
                        className="section-header"
                        style={{ fontSize: "6rem", color: "#ffffff" }}
                      >
                        지금 바로 <span style={{ color: "#FEB948" }}>카몬</span>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={3}>
                    <Grid.Column verticalAlign="top">
                      <CarmonProcessImage image={MOBILE_CONT_301} index={31} />
                    </Grid.Column>

                    <Grid.Column verticalAlign="top">
                      <CarmonProcessImage image={MOBILE_CONT_302} index={32} />
                    </Grid.Column>

                    <Grid.Column verticalAlign="top">
                      <CarmonProcessImage image={MOBILE_CONT_303} index={33} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </MediaQuery>

              <MediaQuery maxWidth={MAX_WIDTH_BASE}>
                <section
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 30,
                    padding: "40px 20px",
                  }}
                >
                  <Grid>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <div
                          className="section-header"
                          style={{ color: "#ffffff" }}
                        >
                          지금 바로{" "}
                          <span style={{ color: "#FEB948" }}>카몬</span>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <CarmonProcessImage
                          image={MOBILE_CONT_301}
                          index={31}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <CarmonProcessImage
                          image={MOBILE_CONT_302}
                          index={32}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <CarmonProcessImage
                          image={MOBILE_CONT_303}
                          index={33}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </section>
              </MediaQuery>
            </section>

            <section
              style={{
                paddingLeft: useMediaQuery({ minWidth: MIN_WIDTH_BASE })
                  ? 100
                  : 20,
                paddingRight: useMediaQuery({ minWidth: MIN_WIDTH_BASE })
                  ? 100
                  : 20,
                paddingBottom: 20,
              }}
            >
              <Container>
                <Button
                  fluid
                  content="지금 바로 예약하세요!"
                  icon="calendar check outline"
                  size={isMobile ? "large" : "huge"}
                  color={"black"}
                  style={{
                    opacity: 0.9,
                    fontSize: useMediaQuery({ minWidth: MIN_WIDTH_BASE })
                      ? 48
                      : 24,
                    padding: useMediaQuery({ minWidth: MIN_WIDTH_BASE })
                      ? 30
                      : 15,
                    boxShadow: "0px 0px 5px #00000055",
                  }}
                  onClick={() => {
                    const top =
                      window.scrollY +
                      document
                        .querySelector("#carmon-reservation-form")
                        .getBoundingClientRect().y -
                      20;
                    setScrollToY(top);
                    window.scrollTo({ top: top, left: 0, behavior: "smooth" });
                  }}
                />
              </Container>
              {/*<Button
                style={{color: '#ffffff', backgroundColor: '#00000066'}}
                className="reservation-scroll-button"
                icon="calendar check outline"
                content="지금 바로 예약하기!"
                onClick={() => {
                  const top = window.scrollY + (document.querySelector('#carmon-reservation-form').getBoundingClientRect().y) - 20;
                  setScrollToY(top);
                  window.scrollTo({top: top, left: 0, behavior: 'smooth'});
                }} />*/}
            </section>

            <section style={{ background: "#f0f0f0", padding: "40px 20px" }}>
              <Container>
                <Header as={"h2"}>
                  <Icon name={"announcement"} /> 보도자료
                </Header>
                {/*{
                  useMediaQuery({maxWidth: MAX_WIDTH_BASE}) ?
                    <Card.Group itemsPerRow={1}>
                      {NEWS_DATA.slice(0).reverse().map((news, idx) => (
                        <Card fluid key={`news_${idx}`} link href={news.link} target={'_blank'}>
                          <div style={{
                            height: 200,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundImage: `url(../images/news/${news.id}.png)`,
                          }} />
                          <Card.Content>
                            <Card.Header>{news.title}</Card.Header>
                            <Card.Meta>{news.lastModified} | 출처 : {news.pressCompany}</Card.Meta>
                            <Card.Description>{news.outline}</Card.Description>
                          </Card.Content>
                        </Card>
                      ))}
                    </Card.Group> :
                    <Slider {...SLIDER_SETTING}
                            ref={slider}>
                      {NEWS_DATA.slice(0).reverse().map((news, idx) => (
                        <div style={{margin: '0 50px'}}>
                          <Card key={`news_${idx}`} link href={news.link} target={'_blank'}
                                style={{width: '95%', margin: '0 10px'}}>
                            <div style={{
                              height: 300,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              backgroundImage: `url(../images/news/${news.id}.png)`,
                            }} />
                            <Card.Content>
                              <Card.Header style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}>{news.title}</Card.Header>
                              <Card.Meta>{news.lastModified} | 출처 : {news.pressCompany}</Card.Meta>
                              <Card.Description>{news.outline}</Card.Description>
                            </Card.Content>
                          </Card>
                        </div>
                      ))}
                    </Slider>
                }*/}
              </Container>
            </section>

            <section style={{ background: "#fff", padding: "40px 20px" }}>
              <Container>
                <Header as={"h2"}>
                  <Icon name={"question circle"} /> 자주묻는 질문
                </Header>

                <Accordion fluid styled>
                  <Accordion.Title
                    style={{ padding: "20px" }}
                    active={activeIndex === 0}
                    index={0}
                    onClick={eventHandler.handleClick}
                  >
                    <Header
                      as={
                        useMediaQuery({ maxWidth: MAX_WIDTH_BASE })
                          ? "h4"
                          : "h2"
                      }
                      color="grey"
                    >
                      <Icon name="dropdown" />
                      소유권 이전에 필요한 서류는 무엇인가요?
                    </Header>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 0}
                    style={{ background: "rgb(250, 250, 250)" }}
                    className="expand"
                  >
                    <AnimateHeight
                      animateOpacity
                      duration={300}
                      height={activeIndex === 0 ? "auto" : 0}
                    >
                      <p style={FAQ_ANSWER_STYLE}>
                        소유권 이전에 필요한 서류는 아래와 같습니다.
                        <br />
                        [필요서류]
                        <br />
                        - 자동차등록증, 자동차세 완납증명서, 신분증, 인감증명서
                        <br />
                        (판매자가 차량 명의자가 아닌 경우, 위임장 및 대리인
                        신분증 추가 필요)
                      </p>
                    </AnimateHeight>
                  </Accordion.Content>

                  <Accordion.Title
                    style={{ padding: "20px" }}
                    active={activeIndex === 1}
                    index={1}
                    onClick={eventHandler.handleClick}
                  >
                    <Header
                      as={
                        useMediaQuery({ maxWidth: MAX_WIDTH_BASE })
                          ? "h4"
                          : "h2"
                      }
                      color="grey"
                    >
                      <Icon name="dropdown" />
                      기존 자동차 보험은 언제 해지해야 하나요?
                    </Header>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 1}
                    style={{ background: "rgb(250, 250, 250)" }}
                    className="expand"
                  >
                    <AnimateHeight
                      animateOpacity
                      duration={300}
                      height={activeIndex === 1 ? "auto" : 0}
                    >
                      <p style={FAQ_ANSWER_STYLE}>
                        이전된 자동차등록증을 문자로 받으신 후 보험사에 연락해서
                        해지 진행하시면 됩니다.
                        <br />
                        단, 보험이 없는 차량은 명의이전이 불가하니 명의 이전
                        전까지 보험은 유지해주셔야합니다. (보험해지는 명의 이전
                        후 일주일, 한달 늦게 신청하셔도 이전일을 기준으로
                        보험해지금 모두 환급 가능합니다.)
                      </p>
                    </AnimateHeight>
                  </Accordion.Content>

                  <Accordion.Title
                    style={{ padding: "20px" }}
                    active={activeIndex === 2}
                    index={2}
                    onClick={eventHandler.handleClick}
                  >
                    <Header
                      as={
                        useMediaQuery({ maxWidth: MAX_WIDTH_BASE })
                          ? "h4"
                          : "h2"
                      }
                      color="grey"
                    >
                      <Icon name="dropdown" />
                      차량 판매금은 언제 입금되나요?
                    </Header>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 2}
                    style={{ background: "rgb(250, 250, 250)" }}
                    className="expand"
                  >
                    <AnimateHeight
                      animateOpacity
                      duration={300}
                      height={activeIndex === 2 ? "auto" : 0}
                    >
                      <p style={FAQ_ANSWER_STYLE}>
                        탁송기사 방문 후 신분증 촬영, 서명 완료시 평균 1시간
                        안에 고객님의 계좌로 입금됩니다.
                      </p>
                    </AnimateHeight>
                  </Accordion.Content>

                  <Accordion.Title
                    style={{ padding: "20px" }}
                    active={activeIndex === 3}
                    index={3}
                    onClick={eventHandler.handleClick}
                  >
                    <Header
                      as={
                        useMediaQuery({ maxWidth: MAX_WIDTH_BASE })
                          ? "h4"
                          : "h2"
                      }
                      color="grey"
                    >
                      <Icon name="dropdown" />
                      할부(근저당)은 어떻게 처리되나요?
                    </Header>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 3}
                    style={{ background: "rgb(250, 250, 250)" }}
                    className="expand"
                  >
                    <AnimateHeight
                      animateOpacity
                      duration={300}
                      height={activeIndex === 3 ? "auto" : 0}
                    >
                      <p style={FAQ_ANSWER_STYLE}>
                        근저당은 개인정보 관계로 직접 해당 캐피탈사에 문의하여
                        처리 하셔야 합니다.
                        <br />
                        캐피탈사에 남은 할부금액 완납 후 근저당 해지서류를
                        받으시면 처리가 완료 됩니다.
                      </p>
                    </AnimateHeight>
                  </Accordion.Content>

                  <Accordion.Title
                    style={{ padding: "20px" }}
                    active={activeIndex === 4}
                    index={4}
                    onClick={eventHandler.handleClick}
                  >
                    <Header
                      as={
                        useMediaQuery({ maxWidth: MAX_WIDTH_BASE })
                          ? "h4"
                          : "h2"
                      }
                      color="grey"
                    >
                      <Icon name="dropdown" />
                      탁송중 사고가 발생한다면 어떻게 되나요?
                    </Header>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 4}
                    style={{ background: "rgb(250, 250, 250)" }}
                    className="expand"
                  >
                    <AnimateHeight
                      animateOpacity
                      duration={300}
                      height={activeIndex === 4 ? "auto" : 0}
                    >
                      <p style={FAQ_ANSWER_STYLE}>
                        차량 인도 이후 차량 운행 또는 보관 중 물리적 문제로 인해
                        발생되는 비용은 모두 탁송 위탁업체 또는 구매하는 카몬
                        회원이 부담합니다.
                      </p>
                    </AnimateHeight>
                  </Accordion.Content>

                  <Accordion.Title
                    style={{ padding: "20px" }}
                    active={activeIndex === 5}
                    index={5}
                    onClick={eventHandler.handleClick}
                  >
                    <Header
                      as={
                        useMediaQuery({ maxWidth: MAX_WIDTH_BASE })
                          ? "h4"
                          : "h2"
                      }
                      color="grey"
                    >
                      <Icon name="dropdown" />
                      과태료를 직접 처리해야 하나요?
                    </Header>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 5}
                    style={{ background: "rgb(250, 250, 250)" }}
                    className="expand"
                  >
                    <AnimateHeight
                      animateOpacity
                      duration={300}
                      height={activeIndex === 5 ? "auto" : 0}
                    >
                      <p style={FAQ_ANSWER_STYLE}>
                        카몬에서는 과태료 여부의 확인만 가능하며, 과태료의
                        납부는 고객님께서 직접 관할 관청을 통해 진행하셔야
                        합니다.
                      </p>
                    </AnimateHeight>
                  </Accordion.Content>

                  <Accordion.Title
                    style={{ padding: "20px" }}
                    active={activeIndex === 6}
                    index={6}
                    onClick={eventHandler.handleClick}
                  >
                    <Header
                      as={
                        useMediaQuery({ maxWidth: MAX_WIDTH_BASE })
                          ? "h4"
                          : "h2"
                      }
                      color="grey"
                    >
                      <Icon name="dropdown" />
                      다른 사람 명의의 차량을 판매할 수 있나요?
                    </Header>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 6}
                    style={{ background: "rgb(250, 250, 250)" }}
                    className="expand"
                  >
                    <AnimateHeight
                      animateOpacity
                      duration={300}
                      height={activeIndex === 6 ? "auto" : 0}
                    >
                      <p style={FAQ_ANSWER_STYLE}>
                        네, 차량 명의자가 아니어도 대리인 자격으로 판매할 수
                        있습니다.
                        <br />
                        단, 예약하실 때는 소유자명을 하셔야 하며, 위임장 및
                        대리인의 신분증을 추가로 준비해 주셔야 합니다.
                      </p>
                    </AnimateHeight>
                  </Accordion.Content>

                  <Accordion.Title
                    style={{ padding: "20px" }}
                    active={activeIndex === 7}
                    index={7}
                    onClick={eventHandler.handleClick}
                  >
                    <Header
                      as={
                        useMediaQuery({ maxWidth: MAX_WIDTH_BASE })
                          ? "h4"
                          : "h2"
                      }
                      color="grey"
                    >
                      <Icon name="dropdown" />
                      정비소 방문 예약일을 변경할 수 있나요?
                    </Header>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 7}
                    style={{ background: "rgb(250, 250, 250)" }}
                    className="expand"
                  >
                    <AnimateHeight
                      animateOpacity
                      duration={300}
                      height={activeIndex === 7 ? "auto" : 0}
                    >
                      <p style={FAQ_ANSWER_STYLE}>
                        기존 예약은 변경하실 수 없으며, 예약을 취소 하신 후 다시
                        예약을 진행 해주셔야 합니다.
                      </p>
                    </AnimateHeight>
                  </Accordion.Content>

                  <Accordion.Title
                    style={{ padding: "20px" }}
                    active={activeIndex === 8}
                    index={8}
                    onClick={eventHandler.handleClick}
                  >
                    <Header
                      as={
                        useMediaQuery({ maxWidth: MAX_WIDTH_BASE })
                          ? "h4"
                          : "h2"
                      }
                      color="grey"
                    >
                      <Icon name="dropdown" />
                      차량 판매는 언제까지 결정해야 하나요?
                    </Header>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 8}
                    style={{ background: "rgb(250, 250, 250)" }}
                    className="expand"
                  >
                    <AnimateHeight
                      animateOpacity
                      duration={300}
                      height={activeIndex === 8 ? "auto" : 0}
                    >
                      <p style={FAQ_ANSWER_STYLE}>
                        경매종료일로부터 3일 안에 결정하셔야 합니다.
                      </p>
                    </AnimateHeight>
                  </Accordion.Content>

                  <Accordion.Title
                    style={{ padding: "20px" }}
                    active={activeIndex === 9}
                    index={9}
                    onClick={eventHandler.handleClick}
                  >
                    <Header
                      as={
                        useMediaQuery({ maxWidth: MAX_WIDTH_BASE })
                          ? "h4"
                          : "h2"
                      }
                      color="grey"
                    >
                      <Icon name="dropdown" />
                      탁송 희망일은 언제까지 결정해야 하나요?
                    </Header>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 9}
                    style={{ background: "rgb(250, 250, 250)" }}
                    className="expand"
                  >
                    <AnimateHeight
                      animateOpacity
                      duration={300}
                      height={activeIndex === 9 ? "auto" : 0}
                    >
                      <p style={FAQ_ANSWER_STYLE}>
                        판매 결정일로부터 3일 안에 결정하셔야 합니다.
                      </p>
                    </AnimateHeight>
                  </Accordion.Content>
                </Accordion>
              </Container>
            </section>

            {/* Page- footer */}
            <div style={{ background: "#28303d" }}>
              <div>
                <Container>
                  <Segment inverted basic size="large">
                    <Grid>
                      <Grid.Row verticalAlign="middle" columns={3}>
                        <Grid.Column textAlign="center">
                          <Image
                            src="/images/logo/partner/kaat.png"
                            size="small"
                            centered
                            as="a"
                            href={"http://kaat.org/"}
                            target={"_blank"}
                            verticalAlign="middle"
                          />
                        </Grid.Column>
                        <Grid.Column textAlign="center">
                          <Image
                            src="/images/logo/partner/carzen.png"
                            size="small"
                            centered
                            as="a"
                            href={"http://www.carzen.co.kr/"}
                            target={"_blank"}
                            verticalAlign="middle"
                          />
                        </Grid.Column>
                        <Grid.Column textAlign="center">
                          <Image
                            src="/images/logo/partner/ucar.png"
                            size="small"
                            centered
                            as="a"
                            href={"https://www.ucar.co.kr/"}
                            target={"_blank"}
                            verticalAlign="middle"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </Container>
              </div>

              <footer>
                <MediaQuery minWidth={MIN_WIDTH_BASE}>
                  <Segment basic inverted style={{ padding: "6px 16px" }}>
                    <Header
                      as={"h2"}
                      style={{
                        color: BASE_STYLES.THEME_ITEM_COLOR,
                        marginBottom: 0,
                      }}
                    >
                      내 차 팔기, 새로운 경험 | 카몬
                    </Header>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <Header
                          as={"h5"}
                          style={{
                            fontSize: BASE_STYLES.FONT_SIZE.SUB,
                            color: BASE_STYLES.THEME_ITEM_COLOR,
                            margin: "0 0 6px 0",
                          }}
                        >
                          대표자 : {CARMON_INFO.REPRESENTATIVE} |
                          개인정보관리책임자 : {CARMON_INFO.PERSONAL_INFO} |
                          사업자등록번호 : {CARMON_INFO.BUSINESS_NUMBER} |
                          통신판매업신고 : {CARMON_INFO.SELLER_NUMBER}
                          <br />
                          사업장소재지 : ({CARMON_INFO.POSTAL_CODE}){" "}
                          {CARMON_INFO.ADDRESS} | 제휴문의 :{" "}
                          <a
                            href={`mailto:${CARMON_INFO.CONTACT_EMAIL}`}
                            style={{
                              color: BASE_STYLES.THEME_ITEM_COLOR,
                              textDecoration: "none",
                            }}
                          >
                            {CARMON_INFO.CONTACT_EMAIL}
                          </a>{" "}
                          <br />
                          고객센터 : {CARMON_INFO.SUPPORT_TEL} (
                          {CARMON_INFO.SUPPORT_TIME})
                        </Header>
                        <Header
                          as={"h5"}
                          style={{
                            fontSize: BASE_STYLES.FONT_SIZE.SUB,
                            color: BASE_STYLES.THEME_ITEM_COLOR,
                            margin: 0,
                          }}
                        >
                          Copyright © 카몬 All Rights Reserved.
                        </Header>
                      </div>
                    </div>
                  </Segment>
                </MediaQuery>
                <MediaQuery maxWidth={MAX_WIDTH_BASE}>
                  <Segment basic inverted style={{ padding: "6px 16px" }}>
                    <Header as={"h2"} style={{ margin: 0 }}>
                      내 차 팔기, 새로운 경험 | 카몬
                    </Header>
                    <hr style={{ margin: "6px 0" }} />
                    <Header style={{ margin: 0, fontSize: 14 }}>
                      대표자 : {CARMON_INFO.REPRESENTATIVE} | 개인정보관리책임자
                      : {CARMON_INFO.PERSONAL_INFO}
                      <br />
                      사업자등록번호 : {CARMON_INFO.BUSINESS_NUMBER}
                      <br />
                      통신판매업신고 : {CARMON_INFO.SELLER_NUMBER}
                      <br />
                      사업장소재지 : ({CARMON_INFO.POSTAL_CODE}){" "}
                      {CARMON_INFO.ADDRESS}
                      <br />
                      제휴문의 :{" "}
                      <a
                        href={`mailto:${CARMON_INFO.CONTACT_EMAIL}`}
                        style={{
                          color: BASE_STYLES.THEME_ITEM_COLOR,
                          textDecoration: "none",
                        }}
                      >
                        {CARMON_INFO.CONTACT_EMAIL}
                      </a>
                      <br />
                      고객센터 : {CARMON_INFO.SUPPORT_TEL} (
                      {CARMON_INFO.SUPPORT_TIME})<br />
                      <br />
                      Copyright © 카몬 All Rights Reserved.
                    </Header>
                  </Segment>
                </MediaQuery>
              </footer>
            </div>
          </Visibility>
        </div>
      </div>
    </React.Fragment>
  );
};

const CarmonProcessImage = ({ image, index }) => {
  const [activeImage, setActiveImage] = useState(-1);

  return (
    <div style={{ position: "relative", width: "100%", textAlign: "center" }}>
      <Image className="processImage" src={image} />
      <div
        style={{
          position: "absolute",
          bottom: "0%",
          right: "0%",
          overflow: "hidden",
        }}
      >
        <Button
          basic
          icon={"plus"}
          content="더보기"
          onClick={() => {
            setActiveImage(activeImage === index ? -1 : index);
          }}
          style={{
            borderRadius: 18,
            boxShadow: "0 0 0 3px rgb(34 36 38 / 50%) inset",
            display: activeImage === index ? "none" : "block",
          }}
        />
      </div>
      <div
        className="processDetailWrapper"
        style={{
          position: "absolute",
          top: activeImage === index ? 0 : "",
          bottom: 0,
          width: "100%",
          textAlign: "center",
          background: activeImage === index ? "#000c" : "#0003",
          height: activeImage === index ? "100%" : 0,
          overflowY: "auto",
        }}
        onClick={() => setActiveImage(-1)}
      >
        <AnimateHeight
          animateOpacity
          duration={300}
          height={activeImage === index ? "auto" : 0}
        >
          <div
            style={{
              display: activeImage === index ? "block" : "none",
              textAlign: "left",
              padding: 20,
              color: "#fff",
            }}
          >
            <CarmonProcessDetail index={index} />
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
};

const CarmonProcessDetail = ({ index }) => {
  const customStyle = {
    fontSize: useMediaQuery({ minWidth: MIN_WIDTH_BASE }) ? "1.3em" : "1em",
    lineHeight: useMediaQuery({ minWidth: MIN_WIDTH_BASE }) ? "1.5em" : "1.1em",
  };

  let returnJSX;

  switch (index) {
    case 11:
      returnJSX = (
        <div style={customStyle}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header inverted as="h2" color="red">
                  카몬이 대신합니다.
                </Header>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "20px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  내차 파는데 앱 설치, 차량 사진 촬영, 옵션과 사고 조회 등등
                  이런거 일일이 입력하느라 지치네요.
                </div>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  요새는 감가 당하지 않으려면 더더욱 꼼꼼히 입력해야
                  한다던데...😭
                </div>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  맨날 타는 차지만, 구매할 때 선택한 옵션이 무엇인지, 그동안
                  언제 사고가났고, 어떻게 수리 했는지, 잘 기억도 안나요.
                </div>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  카몬은 좀 다른가요?
                </div>

                <div
                  style={{
                    float: "right",
                    background: "#ff6c6c",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  네, 카몬에선 이 모든 귀찮은 작업을 국가공인 정비사가
                  전문적으로 모두 대신 해드립니다.
                </div>
                <div
                  style={{
                    float: "right",
                    background: "#ff6c6c",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  내차 팔 때 귀찮음, 더 이상 고민하지 마세요.
                </div>

                <div className="hash-tag-wrapper">
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #차량사진촬영
                  </span>
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #차량옵션확인
                  </span>
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #사고이력확인
                  </span>
                  <span style={{ color: "#FEB948", fontWeight: "bold" }}>
                    #카몬이책임지고대신
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
      break;
    case 12:
      returnJSX = (
        <div style={customStyle}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header inverted as="h2" color="red">
                  카몬에선 딴나라 이야기
                </Header>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "20px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  다른 앱에선, 내차 사겠다고 보러 온다는데, 딜러를 대면하는게
                  조금 무섭고, 일정 잡기도 어렵네요.
                </div>

                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  딜러 안 보고 싶어서 방문평가 신청하니, 평가사란분이 내차 보러
                  온다던데, 처음 보는 사람을 주차장에서 30분 동안 보자니, 여간
                  불편하네요.
                </div>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  카몬은 좀 다른가요?
                </div>

                <div
                  style={{
                    float: "right",
                    background: "#ff6c6c",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  네. 카몬에선, 딜러도 평가사도 만날 필요가 없습니다.
                </div>
                <div
                  style={{
                    float: "right",
                    background: "#ff6c6c",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  집 근처 카몬 제휴 정비소에 차를 맡기기만 하세요. 응접실에서
                  편안하게 차 한잔 하면서 기다리기만 하면 됩니다.
                </div>
                <div
                  style={{
                    float: "right",
                    background: "#ff6c6c",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  내차 팔 때 뻘쭘함, 이제는 걱정하지 마세요.
                </div>

                <div className="hash-tag-wrapper">
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #딜러/평가사비대면
                  </span>
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #내가원하는날
                  </span>
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #집근처에서바로
                  </span>
                  <span style={{ color: "#FEB948", fontWeight: "bold" }}>
                    #세상편한카몬
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
      break;
    case 13:
      returnJSX = (
        <div style={customStyle}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header inverted as="h2" color="red">
                  카몬이 차단합니다.
                </Header>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "20px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  다른 서비스에선, 딜러가 와서 내차 보더니 머라머라 어려운말로
                  말하면서 내차 가격을 너무 깎아버리네요.
                </div>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "00px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  저는 차를 잘 모르니깐 뭐라고 하는지 하나도 모르겠고, 그냥
                  알았다고만 했는데, 나중에 알고 보니 10만원에 고칠 거
                  30만원이라 하고, 군데 군데 바가지도 그런 바가지가 없네요.
                </div>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  카몬은 좀 다른가요?
                </div>

                <div
                  style={{
                    float: "right",
                    background: "#ff6c6c",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  네. 카몬에선 딜러 대면이 없기에, 어려운 말로 현혹당해 부담감가
                  당하는 위험이 없습니다.
                </div>
                <div
                  style={{
                    float: "right",
                    background: "#ff6c6c",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  내차 팔 때 의구심, 앞으론 떨쳐 버리세요.
                </div>

                <div className="hash-tag-wrapper">
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #딜러/평가사비대면
                  </span>
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #사기차단
                  </span>
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #부당감가차단
                  </span>
                  <span style={{ color: "#FEB948", fontWeight: "bold" }}>
                    #믿음의카몬
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
      break;
    case 21:
      returnJSX = (
        <div style={customStyle}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header inverted as="h2" style={{ color: "#fff" }}>
                  카몬은 정확합니다.
                </Header>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "20px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  카몬의 차량진단이 평가사 진단과 뭐가 다르죠? 평가사가 자신을
                  차량전문가라고 하시던데요?
                </div>

                <div
                  style={{
                    float: "right",
                    background: "#FEB948",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  카몬의 차량진단은, 국가공인정비소에서 객관적 장비를 통해
                  정확한 진단이 이루어 집니다. 눈으로만 하는 평가사 진단과는
                  하늘과 땅 차이죠. 차량 성능은 물론, 외관 하부까지도 꼼꼼히
                  진단을 시행합니다.
                </div>

                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  아하! 그래서 딜러가 차량을 보러 저 한테 찾아올 이유가 없는
                  거네요?
                </div>

                <div
                  style={{
                    float: "right",
                    background: "#FEB948",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  네 맞습니다. 편하실 때 집근처 카몬 제휴정비소에 차량을 맡겨만
                  주세요. 차량 진단과 리포트 작성은 카몬이 대신합니다.
                </div>

                <div className="hash-tag-wrapper">
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #국가공인정비소
                  </span>
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #객관적차량진단
                  </span>
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #눈진단은못믿음
                  </span>
                  <span style={{ color: "#FEB948", fontWeight: "bold" }}>
                    #카몬은정확
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
      break;
    case 22:
      returnJSX = (
        <div style={customStyle}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header inverted as="h2" style={{ color: "#fff" }}>
                  카몬은 똘똘합니다.
                </Header>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "20px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  카몬에선 정말 최고가에서 감가없이 내차 판매가 가능한가요?
                </div>
                <div
                  style={{
                    float: "right",
                    background: "#FEB948",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  네. 카몬은 정비소의 정확한 차량 진단 정보를 바탕으로, 딜러는
                  차량을 보러 판매자님을 대면할 필요가 없고, 눈으로만 하는
                  잘못된 진단으로 인한 수리비 폭탄의 걱정도 없어서, 딜러 자신이
                  매입할 수 있는 최고 금액으로 자신있게 입찰 합니다.
                </div>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  아하! 그렇다면 정말 최고가가 가능하겠네요?
                </div>
                <div
                  style={{
                    float: "right",
                    background: "#FEB948",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  네, 맞습니다. 딜러 수수료도 다른 앱보다 낮으니, 딜러는 카몬을
                  훨씬 더 선호하겠죠?
                </div>

                <div className="hash-tag-wrapper">
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #딜러시간비용절약
                  </span>
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #최고가입찰
                  </span>
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #똘똘한카몬
                  </span>
                  <span style={{ color: "#FEB948", fontWeight: "bold" }}>
                    #감가없음
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
      break;
    case 23:
      returnJSX = (
        <div style={customStyle}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header inverted as="h2" style={{ color: "#fff" }}>
                  필요한 건, 커피 한 잔의 시간 뿐
                </Header>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "20px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  카몬에서 내차 팔 때, 수수료가 정말 없나요?
                </div>
                <div
                  style={{
                    float: "right",
                    background: "#FEB948",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  네. 카몬은 다른 내차팔기 앱들과 달리 내차를 파는 고객님은
                  수수료가 일체 없습니다.
                </div>
                <div
                  style={{
                    float: "right",
                    background: "#FEB948",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  그저 집근처 정비소에서 커피 한잔의 여유를 즐길 시간 정도만
                  준비하시면 됩니다.
                </div>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  와우! 카몬을 안 쓸 이유가 없네요?
                </div>
                <div
                  style={{
                    float: "right",
                    background: "#FEB948",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  고객님 칭찬 감사합니다. 수수료 없는 다른 앱들도 있긴 있지만,
                  어차피 딜러 만나고 평가사 만나서 뻘쭘한 시간 보내실 바엔, 집
                  근처 카몬 정비소에서 편하게 진단 받고 판매하세요!
                </div>

                <div className="hash-tag-wrapper">
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #카몬수수료제로
                  </span>
                  <span style={{ color: "#FEB948", fontWeight: "bold" }}>
                    #필요한건커피한잔의여유
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
      break;
    case 31:
      returnJSX = (
        <div style={customStyle}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header inverted as="h2" color="blue">
                  1분이면 OK!
                </Header>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "20px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  카몬 맘에 드네요. 어떻게 이용하면 되죠?
                </div>

                <div
                  style={{
                    float: "right",
                    background: "rgb(0,180,255)",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  딱 1분. 지금 예약 버튼을 클릭하시고, 필수 정보만 입력해
                  주세요.
                </div>
                <div
                  style={{
                    float: "right",
                    background: "rgb(0,180,255)",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  카몬 매니저가 바로 연락드립니다.
                </div>

                <div className="hash-tag-wrapper">
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #1분예약
                  </span>
                  <span style={{ color: "#FEB948", fontWeight: "bold" }}>
                    #카몬매니저매칭
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
      break;
    case 32:
      returnJSX = (
        <div style={customStyle}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header inverted as="h2" color="blue">
                  내 집 앞 정비소 방문
                </Header>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "20px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  카몬 예약을 했는데, 이후는 어떻게 되죠?
                </div>
                <div
                  style={{
                    float: "right",
                    background: "rgb(0,180,255)",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  예약하신 날짜에 정비소를 방문하셔서 차량 진단만 받으시면
                  됩니다.
                </div>
                <div
                  style={{
                    float: "right",
                    background: "rgb(0,180,255)",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  그 이후 모든 절차는 카몬에서 전문적으로 처리해 드립니다.
                </div>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  와우! 정말 편하네요.
                </div>

                <div className="hash-tag-wrapper">
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #예약한날짜
                  </span>
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #정비소방문
                  </span>
                  <span style={{ color: "#FEB948", fontWeight: "bold" }}>
                    #차량진단
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
      break;
    case 33:
      returnJSX = (
        <div style={customStyle}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header inverted as="h2" color="blue">
                  나머지는 카몬이 합니다
                </Header>
                <div
                  style={{
                    background: "#F0F0F0",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "20px 20px 20px 0px",
                    display: "inline-block",
                  }}
                >
                  정비소에서 진단을 받았는데, 이제 뭐하죠? 정말 더 이상 할게
                  없나요?
                </div>

                <div
                  style={{
                    float: "right",
                    background: "rgb(0,180,255)",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  네. 고객님. 차량판매 전에 필요한 서류만 준비해주시면 됩니다.
                </div>
                <div
                  style={{
                    float: "right",
                    background: "rgb(0,180,255)",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  지금부터는 카몬 전문가들이 진단 리포트를 작성하여 딜러 경매를
                  통해 고객님의 차를 최고가로 팔아 드립니다.
                </div>
                <div
                  style={{
                    float: "right",
                    background: "rgb(0,180,255)",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  고객님은 그저 가격만 받아보시고, 판매 결정만 해주세요.
                </div>
                <div
                  style={{
                    float: "right",
                    background: "rgb(0,180,255)",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    margin: "0px 0px 20px 40px",
                    display: "inline-block",
                  }}
                >
                  앗 고객님! 카몬에서는 차가 하루 이내에 판매되니, 판매 완료까지
                  조심조심 안전 운전 해주세요~
                </div>

                <div className="hash-tag-wrapper">
                  <span
                    style={{
                      color: "#FEB948",
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    #내차팔기끝판왕
                  </span>
                  <span style={{ color: "#FEB948", fontWeight: "bold" }}>
                    #세상편한내차팔기
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
      break;
  }

  return returnJSX;
};

export default CarmonPusher;
