import {Grid, Header, Icon, Segment} from 'semantic-ui-react';
import React, {useEffect, useState} from 'react';
import _ from 'lodash';

import ChatLine from './lines/ChatLine';

export default function Chat({style, title: chatTitle, data: chatData}) {
  return (
    <>
      <Header as={'h3'} style={{
        backgroundColor: '#3a1d1d',
        color: '#ffffff',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        border: 0,
      }} attached={'top'}>
        <Icon name={'talk'} fitted />&nbsp;&nbsp;{chatTitle}
      </Header>
      <div style={{
        width: '100%',
        maxHeight: '60vh',
        overflowX: 'hidden',
        overflowY: 'overlay',
        backgroundColor: '#9bbbd4',
        padding: '20px 12px',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
      }}>
        <Grid style={{marginLeft: '0!important', marginRight: '0!important'}}>
          {_.map(chatData, (line, idx) => (
            <ChatLine key={`CL_${idx}`} line={line} lineIdx={idx} />
          ))}
        </Grid>
      </div>
    </>
  );
}