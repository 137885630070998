import React, {useEffect, useRef, useState} from 'react';

const {kakao} = window;

export default function MapContainer({
                                       mapId,
                                       latitude,
                                       longitude,
                                       draggable = true,
                                       mapStyle = {width: '100%', minHeight: 360, maxHeight: '50vh'},
                                     }) {
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [mapRect, setMapRect] = useState(null);

  const initialPosition = new kakao.maps.LatLng(37.50513449327636, 126.88000654510674);

  useEffect(() => {
    const container = document.getElementById(mapId);
    const options = {
      center: initialPosition,
      level: 3,
      draggable,
    };

    mapRef.current = new kakao.maps.Map(container, options);
    markerRef.current = new kakao.maps.Marker({position: initialPosition});
    markerRef.current.setMap(mapRef.current);

    /*
    kakao.maps.event.addListener(mapRef.current, 'center_changed', function() {
      const latlng = mapRef.current.getCenter();

      marker.setMap(null);
      marker.setPosition(latlng);
      marker.setMap(mapRef.current);
    });

    kakao.maps.event.addListener(mapRef.current, 'idle', function() {
      const latlng = marker.getPosition();

      geocoder.coord2Address(latlng.getLng(), latlng.getLat(), (result, status) => {
        if (status === kakao.maps.services.Status.OK) {
          let address = result[0]?.road_address?.address_name;
          if (!address) address = result[0]?.address?.address_name;

          setAddress(address);
        }
      });
    });
    */
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      const newLatLon = new kakao.maps.LatLng(latitude || 37.50513449327636, longitude || 126.88000654510674);
      mapRef.current.panTo(newLatLon);

      markerRef.current.setMap(null);
      markerRef.current.setPosition(newLatLon);
      markerRef.current.setMap(mapRef.current);
    }
  }, [latitude, longitude]);

  useEffect(() => {
    const getRect = () => {
      const element = document.querySelector(`#${mapId}`);

      if (element) {
        const rect = element.getBoundingClientRect();
        const dummyRect = {
          width: rect.width,
          height: rect.height,
          top: element.offsetTop,
          left: element.offsetLeft,
        };

        setMapRect(dummyRect);
      }
    };
    window.addEventListener('scroll', getRect);
    return () => window.removeEventListener('scroll', getRect);
  }, []);

  return (
    <div>
      <div id={mapId} style={mapStyle} />
      {
        !draggable &&
        <div style={{
          ...mapRect,
          position: 'absolute',
          zIndex: 2,
        }} />
      }
    </div>
  );
};
