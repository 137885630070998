export const BASE_STYLES = {
  THEME_COLOR: '#00aed9',
  THEME_SUB_COLOR: '#feb948',
  THEME_ITEM_COLOR: '#f0f0f0',
  THEME_BG_COLOR: '#696969',
  THEME_ATTENTION_COLOR: '#ae0000',
  FONT_COLOR: 'rgb(27, 28, 29)',
  BORDER_COLOR: 'rgba(34,36,38,.1)',
  FONT_SIZE: {
    HEADER: {
      MAIN: 36,
      SUB: 26,
    },
    MAX: 22,
    BASE: 18,
    SUB: 16,
    MIN: 14,
  },
  NAVBAR_HEIGHT: 66,
};

export const CARMON_INFO = {
  COMPANY_NAME: '주식회사 카몬스터',
  COMPANY_ENG_NAME: 'Carmonster Corp.',
  COMPANY_NAME_ABBREVIATION: '카몬',
  REPRESENTATIVE: '성백환',
  PERSONAL_INFO: '성백환',
  REPRESENTATIVE_TEL: '070-7576-4981',
  ADDRESS: '서울특별시 구로구 경인로 579, 비동 3층 308호 (신도림동, 안성빌딩)',
  POSTAL_CODE: '08212',
  BUSINESS_NUMBER: '457-87-02409',
  SELLER_NUMBER: '제 2022-서울구로-0645 호',
  PROVIDE_ONLINE_CAR_INFORMATION: '제 11-1119-000001 호',
  CONTACT_EMAIL: 'carmonster000@gmail.com',
  SUPPORT_TEL: '070-7576-4981',
  SUPPORT_TIME: '평일 10:00 ~ 17:30',
};

export const ReservationCheckExists = {
  NEW: 'N',
  EXISTS: 'E',
};

export const FAQ = [
  ['소유권 이전에 필요한 서류는 무엇인가요?', '소유권 이전에 필요한 서류는 아래와 같습니다.<br />[필요서류]<br />- 자동차등록증, 자동차세 완납증명서, 신분증, 인감증명서<br />(판매자가 차량 명의자가 아닌 경우, 위임장 및 대리인 신분증 추가 필요)'],
  ['기존 자동차 보험은 언제 해지해야 하나요?', '이전된 자동차등록증을 문자로 받으신 후 보험사에 연락해서 해지 진행하시면 됩니다.<br />단, 보험이 없는 차량은 명의이전이 불가하니 명의 이전 전까지 보험은 유지해주셔야합니다. (보험해지는 명의 이전 후 일주일, 한달 늦게 신청하셔도 이전일을 기준으로 보험해지금 모두 환급 가능합니다.)'],
  ['차량 판매금은 언제 입금되나요?', '탁송기사 방문 후 신분증 촬영, 서명 완료시 평균 1시간 안에 고객님의 계좌로 입금됩니다.'],
  ['할부(근저당)은 어떻게 처리되나요?', '근저당은 개인정보 관계로 직접 해당 캐피탈사에 문의하여 처리 하셔야 합니다.<br />캐피탈사에 남은 할부금액 완납 후 근저당 해지서류를 받으시면 처리가 완료됩니다.'],
  ['탁송중 사고가 발생한다면 어떻게 되나요?', '차량 인도 이후 차량 운행 또는 보관 중 물리적 문제로 인해 발생되는 비용은 모두 탁송 위탁업체 또는 구매하는 카몬 회원이 부담합니다.'],
  ['과태료를 직접 처리해야 하나요?', '카몬에서는 과태료 여부의 확인만 가능하며, 과태료의 납부는 고객님께서 직접 관할 관청을 통해 진행하셔야 합니다.'],
  ['다른 사람 명의의 차량을 판매할 수 있나요?', '네, 차량 명의자가 아니어도 대리인 자격으로 판매할 수 있습니다.<br />단, 예약하실 때는 소유자명을 하셔야 하며, 위임장 및 대리인의 신분증을 추가로 준비해 주셔야 합니다.'],
  ['정비소 방문 예약일을 변경할 수 있나요?', '기존 예약은 변경하실 수 없으며, 예약을 취소 하신 후 다시 예약을 진행해 주셔야 합니다.'],
  ['차량 판매는 언제까지 결정해야 하나요?', '경매가 종료된 이후 3일 이내에 결정하셔야 합니다.'],
  ['탁송 희망일은 언제까지 결정해야 하나요?', '판매를 결정한 이후 3일 이내에 결정하셔야 합니다.'],
];

export const PARTNERS = [
  {image: '/images/logo/partner/carzen.png', name: '카젠', link: 'http://www.carzen.co.kr/'},
  {image: '/images/logo/partner/kaat.png', name: '한국자동차기술인협회', link: 'http://kaat.org/'},
  {image: '/images/logo/partner/ucar.png', name: '유카', link: 'https://www.ucar.co.kr/'},
  {image: '/images/logo/partner/gongim.png', name: '공임나라', link: 'https://www.gongim.com/'},
  {image: '/images/logo/partner/carian.png', name: 'AJ카리안서비스', link: 'http://www.carianservice.co.kr/'},
];

//(사)전국자동차매매사업조합연합회
//http://www.carku.co.kr/situation/member.asp

//한국자동차매매사업조합연합회
//http://www.kuca.kr/customer/search.html
