import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import _ from 'lodash';
import axios from 'axios';
import {Button, Dimmer, Divider, Form, Header, Icon, List, Message, Popup, Segment, Table} from 'semantic-ui-react';
import CarmonPrivacyPolicy from '../popup/PrivacyPolicy';
import CarmonReservationResult from '../popup/ReservationResult';
import {ReservationCheckExists} from '../../Consts.js';
import {atom, useRecoilState} from 'recoil';
import {isMobile} from 'react-device-detect';

export const resultModalOpenState = atom({
  key: 'resultModalOpen', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

const ReservationForm = ({formClose}) => {
  const [chkPrivacyPolicy, setChkPrivacyPolicy] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [locationState, setLocationState] = useState([]);
  const [locationRegion, setLocationRegion] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [reservationData, setReservationData] = useState(null);
  const [reservationState, setReservationState] = useState(false);
  const [reservationError, setReservationError] = useState({});
  const [resultModalOpen, setResultModalOpen] = useRecoilState(resultModalOpenState);

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    getValues,
    setValue,
    trigger,
  } = useForm();

  useEffect(() => {
    document.querySelector('#licensePlateNumber').style = `font-size: ${isMobile ? 30 : 48}px; font-weight: bold; text-align: center;`;

    getCommonCode(commonCode.LOCATION_STATE).then(({data}) => {
      let _locationState = [];
      data.map(({code, name}) => {
        _locationState.push({
          key: code,
          value: code,
          text: name,
        });
      });

      setLocationState(_locationState);
    });
  }, []);

  const commonCode = {
    LOCATION_STATE: 'LSTATE',
  };

  const getCommonCode = (codeGroup) => {
    return axios.get(`/apis/common/code_list/${codeGroup}`);
  };

  const getCommonCodeByMasterData = (masterCodeGroup, masterCode) => {
    return axios.get(`/apis/common/code_list/${masterCodeGroup}/${masterCode}`);
  };

  const onChangeLocationState = (selectedVal) => {
    getCommonCodeByMasterData(commonCode.LOCATION_STATE, selectedVal).then(({data}) => {
      let _locationRegion = [];
      data.map(({code, name}) => {
        _locationRegion.push({
          key: code,
          value: code,
          text: name,
        });
      });

      setLocationRegion(_locationRegion);
    });
  };

  const [confirm, setConfirm] = useState(false);
  const onSubmit = () => {
    setReservationError({});
    setConfirm(true);
  };

  const proceedReservation = () => {
    setButtonLoading(true);
    const formData = getValues();

    if (!_.isEmpty(formData)) {
      axios.post('/apis/seller/reservation', formData)
        .then(({data}) => {
          setReservationData(data);
          setResultModalOpen(true);
          setReservationState(ReservationCheckExists.NEW);
          setConfirm(false);
        })
        .catch(({response}) => {
          if (response.status === 409) {
            setReservationData(response.data);
            setResultModalOpen(true);
            setReservationState(ReservationCheckExists.EXISTS);
            setConfirm(false);
          } else if (response.status === 400) {
            setReservationError(response.data);
          } else {
            setReservationError({
              message: '현재 예약이 불가합니다.',
            });
          }
        })
        .finally(() => {
          setTimeout(() => {
            setButtonLoading(false);
          }, 500);
        });
    }
  };

  return (
    <>
      <Header as={'h2'} dividing>
        <Icon name={'edit outline'} /> 예약 정보 입력
      </Header>
      <Segment basic style={{padding: '6px 2px', margin: 0}}>
        <Dimmer active={confirm}>
          {
            _.isEmpty(reservationError)
              ?
              (
                <Segment inverted>
                  <Header as={'h1'}>
                    {
                      buttonLoading
                        ? <span>예약을 진행하고 있습니다.<br /> 잠시만 기다려주세요.</span>
                        : '예약하시겠습니까?'
                    }
                  </Header>
                  <Button disabled={buttonLoading} size={'large'} onClick={() => setConfirm(false)}>취소</Button>
                  <Button loading={buttonLoading} size={'large'} icon={'calendar check outline'} content={'예약하기'}
                          primary
                          onClick={proceedReservation} />
                </Segment>
              )
              :
              (
                <Segment inverted>
                  <Header as={'h1'}>
                    {
                      (_.isEmpty(reservationError?.details) || reservationError?.details.length === 0)
                        ? reservationError.message
                        : reservationError.details.map((detail, idx) => <>
                          <span>{detail}</span>{idx < reservationError.details.length - 1 && <br />}</>)
                    }
                  </Header>
                  <Button disabled={buttonLoading} size={'large'} onClick={() => setConfirm(false)}>닫기</Button>
                </Segment>
              )
          }
        </Dimmer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Field style={{textAlign: 'left', fontSize: '1.3rem', marginBottom: '2vh'}} required>
            <Controller
              name="licensePlateNumber"
              control={control}
              render={({field: {ref, onChange, ...rest}, fieldState: {error}}) => (
                <div style={{border: '1px solid #333333', borderRadius: 8, padding: 2}}>
                  <Form.Input
                    id="licensePlateNumber"
                    style={{
                      border: '4px solid #333333',
                      borderRadius: 8,
                      height: isMobile ? 60 : 80,
                      imeMode: 'active',
                    }}
                    placeholder={'차량번호'}
                    onFocus={e => e.target.select()}
                    onChange={(e, {value}) => {
                      const refinedValue = _.replace(value, /[^0-9ㄱ-ㅎㅏ-ㅣ가-힣ㆍᆢ]/g, '');
                      onChange(refinedValue);
                    }}
                    maxLength={9}
                    {...rest}
                    error={!!errors.licensePlateNumber} />
                </div>
              )}
              defaultValue=""
              rules={{
                required: true,
                pattern: /[가-힣]{0,2}\d{2,3}[가-힣]{1}\d{4}/gm,
              }}
            />
          </Form.Field>

          <Divider horizontal style={{marginTop: 26}}>
            <span style={{color: '#666', fontSize: '1.3rem'}}><Icon name="user outline" /> 예약자</span>
          </Divider>

          <Form.Field style={{textAlign: 'left', fontSize: '1.3rem', marginBottom: '4px'}} required>
            <Header as={'h3'} style={{margin: '0 0 4px 0'}}>
              차량소유자명 <Popup
              flowing
              trigger={<Icon name={'question circle'} color={'yellow'} />}
              content={
                <List as={'ul'}>
                  <List.Item as={'li'}>자동차등록증 상 소유자명이 필요합니다.</List.Item>
                  <List.Item as={'li'}>공동명의일 경우 대표자 1명의 이름만 입력하시면 됩니다.</List.Item>
                  <List.Item as={'li'}><span
                    style={{textDecoration: 'underline', fontWeight: 'bolder'}}>차량정보를 확인하는 용도로만 사용됩니다.</span></List.Item>
                </List>
              }
              position="top center"
            />
            </Header>
            <Controller
              name="name"
              control={control}
              render={({field: {ref, onChange, ...rest}, fieldState: {error}}) => (
                <Form.Input
                  placeholder="자동차등록증 상 소유자명"
                  style={{imeMode: 'active'}}
                  icon={'user outline'}
                  iconPosition={'left'}
                  onFocus={e => e.target.select()}
                  onChange={(e, {value}) => {
                    const refinedValue = _.replace(value, /[^ㄱ-ㅎㅏ-ㅣ가-힣ㆍᆢ]/g, '');
                    onChange(refinedValue);
                  }}
                  {...rest}
                  error={!!errors.name}
                />)}
              defaultValue=""
              rules={{required: true}}
            />
          </Form.Field>

          <Form.Field style={{textAlign: 'left', fontSize: '1.3rem', marginBottom: '2vh'}} required>
            <Header as={'h3'} style={{margin: '16px 0 4px 0'}}>
              연락처
            </Header>
            <Controller
              name="contactNumber"
              control={control}
              render={({field: {ref, onChange, ...rest}, fieldState: {error}}) => (
                <Form.Input
                  placeholder="휴대전화번호"
                  icon={'mobile alternate'}
                  iconPosition={'left'}
                  onFocus={e => e.target.select()}
                  onChange={(e, {value}) => {
                    const refinedValue = _.replace(value, /[^0-9]/g, '');
                    onChange(refinedValue);
                  }}
                  maxLength={13}
                  {...rest}
                  error={!!errors.contactNumber}
                />)}
              defaultValue=""
              rules={{required: true}}
            />
          </Form.Field>

          <Divider horizontal>
            <span style={{color: '#666', fontSize: '1.3rem'}}><Icon name="map marker alternate" /> 거주 지역</span>
          </Divider>

          <Form.Group widths={'equal'} style={{marginBottom: '3vh'}}>
            <Form.Field style={{textAlign: 'left', fontSize: '1.3rem'}}>
              <Controller
                name="locationState"
                control={control}
                render={({field: {ref, ...rest}, fieldState: {error}}) => {
                  return <Form.Select
                    fluid
                    options={locationState}
                    placeholder="광역시, 도"
                    {...rest}
                    onChange={async (e, {name, value}) => {
                      setValue(name, value);
                      onChangeLocationState(value);
                      await trigger('locationState');
                    }}
                    error={!!errors.locationState}
                  />;
                }}
                defaultValue=""
                rules={{required: true}}
              />
            </Form.Field>

            <Form.Field style={{textAlign: 'left', fontSize: '1.3rem'}}>
              <Controller
                name="locationRegion"
                control={control}
                render={({field: {ref, ...rest}, fieldState: {error}}) => {
                  return <Form.Select
                    fluid
                    options={locationRegion}
                    placeholder="시, 구, 군"
                    {...rest}
                    onChange={async (e, {name, value}) => {
                      setValue(name, value);
                      await trigger('locationRegion');
                    }}
                    error={!!errors.locationRegion}
                  />;
                }}
                defaultValue=""
                rules={{required: true}}
              />
            </Form.Field>
          </Form.Group>

          <Form.Field style={{textAlign: 'left'}}>
            <Controller
              name="chkPrivacyPolicy"
              control={control}
              render={({field: {ref, value, onChange}, fieldState: {error}}) => {
                return (
                  <>
                    <Form.Checkbox
                      className="check-privacy-policy-label"
                      label="개인정보 수집/이용 동의 (필수)"
                      onChange={async (e) => {
                        setValue('chkPrivacyPolicy', !value);
                        await trigger('chkPrivacyPolicy');
                      }}
                      required
                      error={!!errors.chkPrivacyPolicy}
                      style={{float: 'left', fontSize: isMobile ? 14 : 18}}
                    />
                    <CarmonPrivacyPolicy linkTitle="보기" style={{float: 'right', fontSize: isMobile ? 14 : 16}} />
                  </>
                );
              }}
              defaultValue={false}
              rules={{
                validate: () => {
                  return getValues('chkPrivacyPolicy');
                },
              }}
            />
          </Form.Field>

          <Form.Button
            style={{marginTop: '7vh'}}
            type="submit"
            size="huge"
            loading={buttonLoading}
            primary
            fluid>
            <Icon name="calendar check outline" />
            예약하기
          </Form.Button>

          <CarmonReservationResult data={reservationData} modalOpen={resultModalOpen}
                                   reservationState={reservationState} />
        </Form>
      </Segment>
    </>
  );
};

export default ReservationForm;
