import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Container,
  Dimmer,
  Divider,
  Form, FormSelect,
  Grid,
  Header,
  Icon, Image,
  Label,
  Loader,
  Modal,
  Segment,
} from 'semantic-ui-react';
import Slider from 'react-slick';
import DaumPostcode from 'react-daum-postcode';
import moment from 'moment';
import _ from 'lodash';
import axios from 'axios';
import {DateInput, TimeInput} from 'semantic-ui-calendar-react-yz';
import GuideImage from '../../images/carmon_delivery.png';

const defaultCheckOwnerState = {
  icon: 'drivers license',
  loading: false,
  color: 'blue',
  content: '차량조회',
  checked: false,
  hasError: false,
  errorMessage: '차량번호 또는 차주명이 입력되지 않았습니다.',
};

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';

const HOUR_OPTIONS = _.range(9, 18, 1).map(v => {
  return {key: v, value: String(v), text: v};
});

const MINUTE_OPTIONS = [
  {key: 0, value: '00', text: '00'},
  {key: 30, value: '30', text: '30'},
];

const NOW = moment();
const todayWeekday = NOW.weekday();
const isTodayFriday = todayWeekday === 5;
const isOnDuty = NOW.hours() > 9 && NOW.hours() < 17;

const isTodaySaturday = todayWeekday === 6;
const isTodaySunday = todayWeekday === 0;

const MAX_COUNT = 15;
let START_DAY, MAX_DAY, HOLIDAYS;

function initReservationAvailableDays() {
  let compensation = 1;

  if (isTodayFriday) {
    if (isOnDuty) compensation = 1;
    else compensation = 4;
  } else if (isTodaySaturday) {
    compensation = 3;
  } else if (isTodaySunday) {
    compensation = 2;
  } else {
    if (!isOnDuty) compensation = 2;
  }

  let startDay = NOW.clone().add(compensation, 'days');
  let endDay;
  let holidays = [];
  let realDayCounter = 0;

  for (let i = 0; i < MAX_COUNT; i++) {
    endDay = startDay.clone().add(i + realDayCounter, 'days');

    if (endDay.weekday() === 0) {
      holidays.push(endDay.format(DATE_FORMAT));
      endDay.add(1, 'days');
      realDayCounter++;
    }
  }

  START_DAY = startDay;
  MAX_DAY = endDay;
  HOLIDAYS = holidays;
}

initReservationAvailableDays();

const CarmonDelivery = (props) => {
  const [checkOwnerState, setCheckOwnerState] = useState(defaultCheckOwnerState);
  const [carInformation, setCarInformation] = useState({});
  const [form, setForm] = useState({
    ownerName: '',
    licensePlateNumber: '',
    resHours: '9',
    resMinutes: '00'
  });

  const [formError, setFormError] = useState({});
  const [formLoader, setFormLoader] = useState({loading: false});

  const [open, setOpen] = useState(false);
  const [isPostCodeOpened, setIsPostCodeOpened] = useState(false);
  const [errorModal, setErrorModal] = useState({
    open: false,
    message: null
  });
  const [step, setStep] = useState(0);
  const slider = useRef();

  const init = () => {
    setCheckOwnerState(defaultCheckOwnerState);
    setCarInformation({});
    setFormError({});
    setFormLoader({loading: false});
    setOpen(false);
    setIsPostCodeOpened(false);
    setErrorModal({open: false, message: null});
    setForm({ownerName:'', licensePlateNumber: ''});
    setStep(0);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if(!open) props.popupClose();
  }, [open]);

  useEffect(() => {
    setOpen(props.active);
  }, [props]);


  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    draggable: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    customPaging: (i) => {
      return <Label circular color={i === step ? 'yellow' : 'grey'} empty disabled />
    },
    beforeChange: (current, next) => {
      setStep(next);
    }
  };

  const checkOwner = () => {
    setCheckOwnerState(prevState => {
      return {
        ...prevState,
        loading: true,
        checked: false,
        hasError: false,
      }
    });

    if (_.isEmpty(form.ownerName) || _.isEmpty(form.licensePlateNumber)) {
      setCheckOwnerState(prevState => {
        return {
          ...prevState,
          loading: false,
          checked: false,
          hasError: true,
        }
      });

      return;
    }

    axios.post('/apis/carmon-delivery/car-information', form)
      .then(({data}) => {
        setCarInformation(data);

        setForm(prevState => {
          const formData = {
            ...prevState,
            carmonDeliveryId: data.carmonDeliveryId,
            isNewReservation: data.isNewReservation,
            resTime: '9:00'
          };

          let resTimeSplit;
          try {
            resTimeSplit = data.resTime.split(':');
            if (resTimeSplit.length !== 2) resTimeSplit = ['9', '00'];
          } catch (ex) {
            resTimeSplit = ['9', '00'];
          }

          if (!data.isNewReservation) {
            _.merge(formData, {
              contactNumber: data.contactNumber,
              resDate: data.resDate,
              resTime: `${resTimeSplit[0]}:${resTimeSplit[1]}`,
              resHours: resTimeSplit[0],
              resMinutes: resTimeSplit[1],
              sido: data.sido,
              sigungu: data.sigungu,
              address: data.address,
              addressDetail: data.addressDetail
            });
          }

          return formData;
        });

        setCheckOwnerState(prevState => {
          return {
            ...prevState,
            icon: 'check',
            color: 'green',
            checked: true,
          }
        });
      })
      .catch(({response}) => {
        setErrorModal({open: true, message: response.data.message});
      }).finally(() => {
        setCheckOwnerState(prevState => {
          return {
            ...prevState,
            loading: false
          }
        });
    });
  };

  const reservation = () => {
    const hasError = checkFormValidate();
    if (hasError) return;

    setFormLoader({loading: true, message: '예약을 진행하고있습니다.'});

    axios.post('/apis/carmon-delivery/reservation', form)
      .then(({data}) => {
        slider.current.slickNext();
        document.getElementById('carmonDeliveryPopupContent').scrollTo({top: 0, left: 0, behavior: 'smooth'});
      })
      .catch(error => {
        setErrorModal({open: true, message: error.message});
      })
      .finally(() => {
        setFormLoader({loading: false});
      });
  }

  const checkFormValidate = () => {
    let hasError = false;
    setFormError({});

    if (!checkOwnerState.checked) {
      setErrorModal({open: true, message: '소유주 검증이 필요합니다.'});
      return;
    }

    if (_.isEmpty(form.contactNumber)) {
      hasError = true;
      setFormError(prevState => {
        return {
          ...prevState,
          contactNumber: '연락처를 입력해주세요.'
        }
      });
    }

    if (_.isEmpty(form.resDate)) {
      hasError = true;
      setFormError(prevState => {
        return {
          ...prevState,
          resDate: '예약 날짜를 입력해주세요.'
        }
      });
    }

    if (_.isEmpty(form.resTime)) {
      hasError = true;
      setFormError(prevState => {
        return {
          ...prevState,
          resTime: '예약 시간을 입력해주세요.'
        }
      });
    }

    if (_.isEmpty(form.resTime)) {
      hasError = true;
      setFormError(prevState => {
        return {
          ...prevState,
          resTime: '예약 시간을 입력해주세요.'
        }
      });
    }

    const [hour, minutes] = form.resTime.split(":");

    if (!_.isEmpty(form.resTime) && (9 > hour || hour > 18)) {
      hasError = true;
      setFormError(prevState => {
        return {
          ...prevState,
          resTime: '예약 시간은 9시부터 18시 사이로 입력해주세요.'
        }
      });
    }

    if (_.isEmpty(form.address)) {
      hasError = true;
      setFormError(prevState => {
        return {
          ...prevState,
          address: '주소를 입력해주세요.'
        }
      });
    }

    return hasError;
  }

  const carmonDeliveryPopupClose = () => {
    init();
    setOpen(false);
  };

  return (
    <Modal
      closeIcon={false}
      open={open}
      onClose={carmonDeliveryPopupClose}
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <Modal.Header>
        카몬 딜리버리<Label tag size={'mini'}>beta</Label>
        <Button basic floated={'right'} icon={'times'} size={'mini'} onClick={carmonDeliveryPopupClose} />
      </Modal.Header>

      <Modal.Content id={'carmonDeliveryPopupContent'} scrolling>
        <Segment basic>
          <Slider ref={slider} {...settings}>

            <Segment basic>
              <Header as={'h3'}>카몬 딜리버리란?</Header>
              <div style={{fontSize: '1.2em'}}>
                <p>"평일에는 시간내기가 힘들고, 주말은 예약이 힘들고"</p>
                <p>정비소 방문이 불편한 고객님을 위해 카몬이 특별한 딜리버리 서비스를 시작합니다!</p>
              </div>

              <Image src={GuideImage} />
            </Segment>

            <Segment basic>
              <Dimmer inverted active={formLoader.loading} style={{zIndex: 1001}}>
                <Loader size='big'>{formLoader.message}</Loader>
              </Dimmer>

              <Form>
                <Grid centered style={{marginBottom: '20px'}}>
                  <Grid.Row>
                    <Grid.Column>
                      <Header>
                        1. 차량정보 입력
                        <Header.Subheader>차량정보 확인을 위해 아래 정보를 입력해주세요.</Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Form.Group widths='3'>
                        <Form.Input label={'차량번호'}
                                    value={form.licensePlateNumber || ''}
                                    readOnly={checkOwnerState.checked || checkOwnerState.loading}
                                    onChange={(e, {value}) => {
                                      setForm(prevState => {
                                        return {...prevState, licensePlateNumber: value}
                                      });
                                    }}/>

                        <Form.Input label={'차주명'}
                                    value={form.ownerName || ''}
                                    readOnly={checkOwnerState.checked || checkOwnerState.loading}
                                    onChange={(e, {value}) => {
                                      setForm(prevState => {
                                        return {...prevState, ownerName: value}
                                      });
                                    }}
                                    action={
                                      <Button icon={checkOwnerState.icon}
                                              loading={checkOwnerState.loading}
                                              color={checkOwnerState.color}
                                              content={checkOwnerState.content}
                                              disabled={checkOwnerState.checked || checkOwnerState.loading}
                                              onClick={checkOwner} />
                                    } />
                      </Form.Group>
                      <Label basic color='red' pointing style={{display: checkOwnerState.hasError ? 'inline-block': 'none'}}>
                        {checkOwnerState.errorMessage}
                      </Label>
                      {
                        !_.isEmpty(carInformation) ?
                          <>
                            <Label color={'orange'}>{carInformation.makerName}</Label>
                            <Label color={'orange'}>{carInformation.modelName}</Label>
                            <Label color={'orange'}>{carInformation.seriesName}</Label>
                            <Label color={'orange'}>{carInformation.year} 년식</Label>
                            <Label color={'orange'}>{carInformation.color}</Label>
                            <Label color={'orange'}>{carInformation.fuel}</Label>
                          </>
                          : ''
                      }


                    </Grid.Column>
                  </Grid.Row>

                  <Divider />

                  <Grid.Row>
                    <Grid.Column>
                      <Header disabled={!checkOwnerState.checked}>
                        2. 추가정보 입력
                        <Header.Subheader>카몬 딜리버리 탁송기사 배정을 위해 아래 정보를 입력해주세요.</Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Dimmer.Dimmable dimmed={!checkOwnerState.checked}>
                        <Dimmer active={!checkOwnerState.checked}
                                content={
                                  <Header as={'h3'} style={{color: 'grey'}}>
                                    소유자 검증을 먼저 진행해주세요.
                                  </Header>
                                }
                                inverted />

                        <Form.Group widths='3'>
                          <Form.Input label={'연락처'}
                                      error={formError.contactNumber}
                                      value={form.contactNumber || ''}
                                      onChange={(e, {value}) => {
                                        setForm(prevState => {
                                          return {...prevState, contactNumber: value}
                                        });
                                      }} />

                          <DateInput
                            label={'날짜'}
                            inline={false}
                            closable={true}
                            localization={'ko'}
                            dateFormat={DATE_FORMAT}
                            minDate={START_DAY.format(DATE_FORMAT)}
                            maxDate={MAX_DAY.format(DATE_FORMAT)}
                            disable={HOLIDAYS}
                            value={form.resDate || ''}
                            error={formError.resDate}
                            onChange={(e, {value}) => {
                              setForm(prevState => {
                                return {...prevState, resDate: value}
                              });
                            }} />

                          <Form.Group>
                            <Form.Dropdown label={'시간'}
                                           placeholder={'시'}
                                           compact
                                           selection
                                           options={HOUR_OPTIONS}
                                           value={form.resHours || ''}
                                           onChange={(e, {value}) => {
                                             setForm(prevState => {
                                               return {
                                                 ...prevState,
                                                 resHours: value,
                                                 resTime: `${value}:${prevState['resMinutes']}`
                                               }
                                             });
                                           }}/>
                            <Form.Dropdown label={'(분)'}
                                           placeholder={'분'}
                                           compact
                                           selection
                                           options={MINUTE_OPTIONS}
                                           value={form.resMinutes || ''}
                                           onChange={(e, {value}) => {
                                             setForm(prevState => {
                                               return {
                                                 ...prevState,
                                                 resMinutes: value,
                                                 resTime: `${prevState['resHours']}:${value}`
                                               }
                                             });
                                           }}/>
                          </Form.Group>


                          {/*<TimeInput*/}
                          {/*  label={'시간 (09:00 ~ 18:00)'}*/}
                          {/*  localization={'ko'}*/}
                          {/*  dateFormat={TIME_FORMAT}*/}
                          {/*  disableMinute={false}*/}
                          {/*  closable={true}*/}
                          {/*  value={form.resTime || ''}*/}
                          {/*  error={formError.resTime}*/}
                          {/*  onChange={(e, {value}) => {*/}
                          {/*    setForm(prevState => {*/}
                          {/*      return {...prevState, resTime: value}*/}
                          {/*    });*/}
                          {/*  }} />*/}
                        </Form.Group>
                      </Dimmer.Dimmable>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Dimmer.Dimmable dimmed={!checkOwnerState.checked}>
                        <Dimmer active={!checkOwnerState.checked} inverted />
                        <Form.Group widths='equal'>
                          <Form.Input label={'주소'}
                                      error={formError.address}
                                      readOnly={true}
                                      value={form.address || ''}
                                      action={
                                        <Button icon={'search'}
                                                onClick={() => setIsPostCodeOpened(true)} />
                                      }
                                      onChange={(e, {value}) => {
                                        setForm(prevState => {
                                          return {...prevState, address: value}
                                        });
                                      }} />

                          <Form.Input label={'나머지 주소'}
                                      error={formError.addressDetail}
                                      value={form.addressDetail || ''}
                                      onChange={(e, {value}) => {
                                        setForm(prevState => {
                                          return {...prevState, addressDetail: value}
                                        });
                                      }} />
                        </Form.Group>
                      </Dimmer.Dimmable>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column textAlign={'center'}>
                      <Dimmer.Dimmable dimmed={!checkOwnerState.checked}>
                        <Dimmer active={!checkOwnerState.checked} inverted />
                        <Form.Button
                          size={'big'}
                          color={'teal'}
                          icon={'paper plane'}
                          content={'카몬 딜리버리 신청'}
                          onClick={reservation}/>
                      </Dimmer.Dimmable>
                    </Grid.Column>
                  </Grid.Row>

                </Grid>
              </Form>
            </Segment>

            <Segment basic textAlign='center'>
              <Header icon>
                <Icon name='check circle' color={'green'}/>
                카몬 딜리버리 서비스가 정상적으로 접수되었습니다!
                <Header.Subheader>
                  진행 상황은 알림톡으로 발송되니 참고하세요.
                </Header.Subheader>
              </Header>
            </Segment>
          </Slider>
        </Segment>

      </Modal.Content>

      <Modal.Actions>
        <Button floated={'left'}
                onClick={() => slider.current.slickGoTo(0)}
                disabled={step === 2}>카몬 딜리버리란?</Button>
        <Button color={step < 2 ? 'blue' : 'red'}
                onClick={() => {
                  if (step < 2) {
                    slider.current.slickNext();
                  } else {
                    carmonDeliveryPopupClose();
                  }
                }}
                disabled={step === 1} >
          {
            step < 2 ?
              <>예약하기 <Icon name={'chevron right'} /></>
              :
              <><Icon name={'times'} /> 닫기</>
          }
          </Button>
      </Modal.Actions>

      <Modal
        closeIcon
        size={'small'}
        open={isPostCodeOpened}
        onOpen={() => setIsPostCodeOpened(true)}
        onClose={() => setIsPostCodeOpened(false)}>
        <Modal.Header>
          주소선택
        </Modal.Header>
        <Modal.Content style={{padding: 0}}>
          <DaumPostcode
            defaultQuery={''}
            onComplete={(result) => {
              setForm(prevState => {
                return {
                  ...prevState,
                  address: result.address,
                  sido: result.sido,
                  sigungu: result.sigungu,
                }
              })
              setIsPostCodeOpened(false);
            }}
          />
        </Modal.Content>
      </Modal>

      <Modal
        closeIcon
        size={'small'}
        open={errorModal.open}
        onClose={() => setErrorModal({open: false})}>
        <Modal.Header>
          <Header as={'h4'} color={'red'}>
            <Icon name={'warning sign'}/>
            ERROR
          </Header>
        </Modal.Header>
        <Modal.Content>
          {
            errorModal.message
          }
        </Modal.Content>
      </Modal>


    </Modal>
  );

}



export default CarmonDelivery;