import React, {useContext, useEffect} from 'react';
import {Header, Segment} from 'semantic-ui-react';
import {motion, useAnimation} from 'framer-motion';
import ReservationContext from '../../../stores/ReservationContext';

export default function ReservationStepOuter({myStep, isLoading, children}) {
  const {allSteps, currentStep, backToPrevStep, goToNextStep} = useContext(ReservationContext);
  const controls = useAnimation();

  useEffect(() => {
    if (currentStep === myStep) {
      controls.start({
        scale: [1, 1.02, 1],
        transition: {duration: 0.6},
      });
    }
  }, [currentStep]);

  return (
    <motion.div
      animate={controls}
      style={{marginBottom: 16}}>
      <Segment
        id={`reservation-step${myStep}`}
        basic
        disabled={currentStep !== myStep}
        loading={isLoading}
        style={{padding: 0}}>
        {children}
      </Segment>
    </motion.div>
  );
}
