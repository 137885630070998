import {Button, Icon, Image, Menu, Modal, Segment} from 'semantic-ui-react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import React, {useState} from 'react';
import MediaQuery from 'react-responsive';
import {BASE_STYLES} from '../../Consts';
import _ from 'lodash';

const MIN_WIDTH_BASE = 760;
const MAX_WIDTH_BASE = MIN_WIDTH_BASE + 1;

export default function Navbar({isInverted, isMenuHidden}) {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  const MENUS = [
    {title: '서비스 소개', hash: 'about', icon: 'question'},
    {title: '이용방법', hash: 'howto', icon: 'talk'},
    {title: '자주 묻는 질문', hash: 'faq', icon: 'conversation'},
    {
      title: '지금 내 차 팔기',
      hash: 'reservation',
      icon: 'calendar check outline',
      color: 'yellow',
      location: '/reservation/step1',
    },
  ];

  return (
    <div style={{
      width: 'clamp(360px, 100vw, 1200px)',
      padding: '10px 12px',
      height: BASE_STYLES.NAVBAR_HEIGHT,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }}>
      <Image
        as={Link}
        to={'/'}
        src={!isInverted ? '/images/logo/carmon.png' : '/images/logo/carmon-black.png'}
        width={180}
        onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }} />
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}>
        <MediaQuery minWidth={MAX_WIDTH_BASE}>
          {
            isInverted &&
            <Segment basic compact inverted={!isInverted}>
              {_.map(MENUS, (menu, idx) => (
                <Button
                  basic={!_.has(menu, 'color')}
                  key={`MI_${idx}`}
                  active={location.pathname === menu.hash}
                  name={menu.hash}
                  as={'a'}
                  data-href={`#${menu.hash}`}
                  color={menu.color}
                  style={{flexBasis: '100%', boxShadow: '0 0 0 0 #ffffff'}}
                  onClick={() => {
                    if (_.has(menu, 'location')) {
                      navigate(menu.location);
                    } else {
                      const top = window.scrollY + (document.querySelector(`#${menu.hash}`).getBoundingClientRect().y) - BASE_STYLES.NAVBAR_HEIGHT;
                      window.scrollTo({top: top, left: 0, behavior: 'smooth'});
                    }
                  }}>
                  {menu.icon && menu.hash === 'reservation' && <Icon name={menu.icon} />}{menu.title}
                </Button>
              ))}
            </Segment>
          }
        </MediaQuery>
        <MediaQuery maxWidth={MIN_WIDTH_BASE}>
          {isInverted && <Icon
            link
            fitted
            name={'sidebar'}
            style={{
              fontSize: 26,
              color: isInverted ? '#000000' : '#ffffff',
            }}
            onClick={() => setMobileMenuOpened(true)} />}
        </MediaQuery>
      </div>

      <MediaQuery maxWidth={MIN_WIDTH_BASE}>
        <Modal
          open={mobileMenuOpened}
          centered={false}
          basic>
          <Segment
            basic
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              alignSelf: 'stretch',
              padding: '0 20px',
            }}>
            <Icon
              name={'close'}
              link
              size={'big'}
              onClick={() => setMobileMenuOpened(false)}
              fitted
              style={{position: 'absolute', top: -10, right: 4}} />
            <Menu size={'huge'} vertical fluid text inverted>
              {_.map(MENUS, (menu, idx) => (
                <Menu.Item
                  key={`MI_${idx}`}
                  active={location.pathname === menu.hash}
                  name={menu.hash}
                  as={'a'}
                  data-href={`#${menu.hash}`}
                  onClick={() => {
                    setMobileMenuOpened(false);

                    if (_.has(menu, 'location')) {
                      navigate(menu.location);
                    } else {
                      const top = window.scrollY + (document.querySelector(`#${menu.hash}`).getBoundingClientRect().y) - BASE_STYLES.NAVBAR_HEIGHT;
                      window.scrollTo({top: top, left: 0, behavior: 'smooth'});
                    }
                  }}>
                  <Icon name={menu.icon} />&nbsp;{menu.title}
                </Menu.Item>
              ))}
            </Menu>
          </Segment>
        </Modal>
      </MediaQuery>
    </div>
  );
}
