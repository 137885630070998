import React from 'react';

export default function MyBubble({chat, lineIdx, bbIdx, fontStyles}) {
  return (
    <div
      data-line-idx={lineIdx}
      data-bb-for={'my'}
      data-bb-idx={bbIdx}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0 8px',
      }}>
      <div className={'speech-bubble-own'} style={{...fontStyles}}>
        {chat}
      </div>
    </div>
  );
}