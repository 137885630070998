import React from 'react';
import {Sticky, Grid, Icon, Button, Header, Image, List, Container} from 'semantic-ui-react';
import {BASE_STYLES, CARMON_INFO} from '../Consts';

export default function CarmonFooter() {
  return (
    <div>
      <Container>
        <Grid stackable stretched>
          <Grid.Row>
            <Grid.Column width={5}>
              <div>
                <Image src={'/images/logo/carmon.png'} style={{width: 200, height: 'auto'}} />
                <Header>{CARMON_INFO.COMPANY_NAME_ABBREVIATION} | {CARMON_INFO.COMPANY_NAME}</Header>
                <div style={{marginBottom: 20}}>내 차 팔기, 새로운 경험<br />중고차? 다 카몬이랑 해!</div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 6,
                }}>
                  {/*<Button circular color="facebook" icon="facebook" />*/}
                  {/*<Button circular color="twitter" icon="twitter" />*/}
                  {/*<Button circular color="linkedin" icon="linkedin" />*/}
                  {/*<Button circular color="google plus" icon="google plus" />*/}
                  <Image src={'/images/icons/naver-blog.png'} style={{width: 32, height: 32, cursor: 'pointer'}}
                         onClick={() => window.open('https://blog.naver.com/car-monster', '_blank')} />
                  <Button circular color="youtube" icon="youtube" title={'카몬 유튜브'}
                          onClick={() => window.open('https://www.youtube.com/channel/UCyzRHfTywuEHqfCEsdtC6KQ', '_blank')} />
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={3}>
              <div>
                <Header as={'h3'}>
                  <Header.Content>서비스</Header.Content>
                </Header>
                <List link>
                  <List.Item active as="a" onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'auto'})}>내 차
                    팔기 - <b style={{color: BASE_STYLES.THEME_SUB_COLOR}}>카몬</b></List.Item>
                  <List.Item active as="a" href={process.env.CARNAWA_SITE_URL} target={'_blank'}>중고차 가격비교
                    - <b style={{color: BASE_STYLES.THEME_SUB_COLOR}}>카나와</b></List.Item>
                  <List.Item active as="a" href={process.env.REPAIR_HISTORY_SITE_URL} target={'_blank'}>숨은 보상금 찾기
                    - <b style={{color: BASE_STYLES.THEME_SUB_COLOR}}>카나와</b></List.Item>
                  <List.Item active as="a" href={process.env.DEALER_SITE_URL} target={'_blank'}>카몬 딜러 사이트</List.Item>
                </List>
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as={'h3'}>
                <Header.Content>회사정보</Header.Content>
              </Header>
              <span style={{margin: 0, fontSize: 14}}>
                대표자 : {CARMON_INFO.REPRESENTATIVE}<br />
                개인정보관리책임자 : {CARMON_INFO.PERSONAL_INFO}<br />
                사업자등록번호 : {CARMON_INFO.BUSINESS_NUMBER}<br />
                통신판매업신고 : {CARMON_INFO.SELLER_NUMBER}<br />
                사업장소재지 : ({CARMON_INFO.POSTAL_CODE}) {CARMON_INFO.ADDRESS}<br />
                제휴문의 : <a href={`mailto:${CARMON_INFO.CONTACT_EMAIL}`} style={{
                color: BASE_STYLES.THEME_SUB_COLOR,
                textDecoration: 'none',
              }}>{CARMON_INFO.CONTACT_EMAIL}</a><br />
                고객센터 : {CARMON_INFO.SUPPORT_TEL} ({CARMON_INFO.SUPPORT_TIME})
              </span>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Header textAlign={'right'}>
          <Header.Content>2022 © {CARMON_INFO.COMPANY_NAME}. All Rights Reserved.</Header.Content>
        </Header>
      </Container>
    </div>
  );
}