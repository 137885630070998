import {Message} from 'semantic-ui-react';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(3);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const newTimeLeft = timeLeft - 1;
      if (newTimeLeft === 0) navigate('/', {replace: true});
      else setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [history, timeLeft]);

  return (
    <div className={'carmon-view-container'}
         style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <Message error size={'massive'} icon={'exclamation triangle'}
               header={'페이지를 찾을 수 없습니다.'}
               content={() => <span>{timeLeft}초 뒤에 이동합니다.</span>}
               style={{maxWidth: 800}} />
    </div>
  );
}